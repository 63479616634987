<template>
  <div data-tour="establishment-home">
    <b-alert v-if="subscriptions.subscription" :show="!isSubscriptionActive" variant="danger" class="h5">
      <div class="p-3">
        <feather-icon
          size="24"
          icon="AlertTriangleIcon"
          class="mr-2 align-middle"
        />
        Tu cuenta presenta un saldo pendiente de pago, por favor renueva el plan o comunícate con nosotros para resolverlo.
        
      </div>
    </b-alert>
    <component v-if="userData.role_name !== 'establishment_supplier'"  :is="currentEstablishment.establishment_type === 'corporate_education' ? 'service-establishment-view' : 'product-establishment-view'" />
    <component v-if="userData.role_name === 'establishment_supplier'"  :is="'establishment-supplier-view'" />
    
    <v-tour name="establishmentHomeTour" :steps="filteredSteps"/>
    <feather-icon 
      icon="HelpCircleIcon" 
      size="24" 
      class="fixed-text help-button" 
      @click="handleHelp"
    />
  </div>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex'
import ServiceEstablishmentView from './ServiceEstablishmentView.vue'
import ProductEstablishmentView from './ProductEstablishmentView.vue'
import EstablishmentSupplierView from './EstablishmentSupplierView.vue'
import subscriptions from '@/store/subscriptions'

export default {
  components: {
    ServiceEstablishmentView,
    ProductEstablishmentView,
    EstablishmentSupplierView,
  },
  data() {
    return {
      showDismissibleAlert: false,
      userData: JSON.parse(localStorage.getItem('userData')),
      steps: [
        {
          target: '[data-tour="establishment-home"]',  
          content: '¡Bienvenido al tour del establecimiento! En esta sección, encontrarás varios botones que te permiten acceder a diferentes funcionalidades de la aplicación.',
        },
        {
          target: '[data-tour="proveeduria"]',
          content: 'Haz clic en este botón para ver las sucursales disponibles en tu establecimiento.',
        },
        {
          target: '[data-tour="sucursales"]',
          content: 'Haz clic en este botón para ver las sucursales disponibles en tu establecimiento.',
        },
        {
          target: '[data-tour="clientes"]',
          content: 'Haz clic en este botón para ver los clientes registrados en tu establecimiento.',
        },
        {
          target: '[data-tour="proveedores"]',
          content: 'Haz clic en este botón para ver los proveedores registrados en tu establecimiento.',
        },
        {
          target: '[data-tour="empleados"]',
          content: 'Haz clic en este botón para ver los empleados registrados en tu establecimiento.',
        },
        {
          target: '[data-tour="balance"]',
          content: 'Haz clic en este botón para ver o agregar el balance de tu establecimiento.',
        },
        {
          target: '[data-tour="facturas"]',
          content: 'Haz clic en este botón para ver las facturas de tu establecimiento.',
        },
        {
          target: '[data-tour="stripe"]',
          content: 'Haz clic en este botón para ver la configuración de Stripe de tu establecimiento.',
        },
        {
          target: '[data-tour="planes"]',
          content: 'Haz clic en este botón para ver los planes de tu establecimiento.',
        },
        {
          target: '[data-tour="configuracion"]',
          content: 'Haz clic en este botón para ver la configuración general de tu establecimiento.',
        }
      ],
    }
  },
  created() {
    this.fetchEstablishment(this.$route.params.id)
  },
  updated() {
    //this.showDismissibleAlert = this.subscriptions.subscription.stripe_status !== 'active' || this.subscriptions.subscription.stripe_status !== 'trialing'
  },
  methods: {
    ...mapActions('establishments', ['fetchEstablishment']),
    handleHelp() {
      this.$tours['establishmentHomeTour'].start()
    },
  },
  computed: {
    ...mapState(["subscriptions"]),
    ...mapGetters("subscriptions", ["isSubscriptionActive"]),
    ...mapGetters("establishments", ["currentEstablishment"]),

    filteredSteps(){
      return this.steps.filter(step => {
        if (step.target === '[data-tour="proveeduria"]') {
          return this.userData.role_name === 'establishment_supplier';
        } else if (step.target === '[data-tour="sucursales"]' || 
                  step.target === '[data-tour="clientes"]') {
          return this.userData.role_name !== 'establishment_supplier';
        } else if (step.target === '[data-tour="proveedores"]') {
          return this.userData.role_name === 'admin' || 
                this.userData.role_name === 'establishment_admin';
        } else if (step.target === '[data-tour="empleados"]' ||
                  step.target === '[data-tour="balance"]' || 
                  step.target === '[data-tour="facturas"]' || 
                  step.target === '[data-tour="planes"]' || 
                  step.target === '[data-tour="configuracion"]') {
          return this.userData.role_name !== 'establishment_presale_clerk' && 
                this.userData.role_name !== 'establishment_supplier';
        } else if (step.target === '[data-tour="stripe"]') {
          return this.userData.role_name === 'establishment_admin';
        }
        return true;
      });
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

.fixed-text {
  font-size: 13px !important;
  color: $primary !important;
}

.help-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}	

</style>

import Vue from 'vue'
import dayjs from 'dayjs'
import { isToday } from './utils'

Vue.filter('money', value => {
  const val = (value / 1).toFixed(2)
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

Vue.filter('fullDate', value => dayjs(value).format('DD MMM YYYY'))
Vue.filter('date', value => dayjs(value).format('DD MMM'))
Vue.filter('dateNtime', value => dayjs(value).format('DD MMM - HH:mm'))
Vue.filter('dateNtime2', value => dayjs(value).format('DD MMM YY - HH:mm'))
Vue.filter('time', value => dayjs(value).format('HH:mm'))
Vue.filter('hour', value => dayjs(value).format('HH'))

Vue.filter('status', value => {
  const status = {
    false: 'Inactivo',
    true: 'Activo',
  }
  return status[value]
})

Vue.filter('orderType', value => {
  const status = {
    inventory_loss: 'Inventario perdido',
    sell: 'Venta',
    buy: 'Compra',
    emida: 'Recarga ó Pago de servicios',
    loan_payment: 'Abono a deuda',
    inventory_correction: 'Correción de inventario',
  }
  return status[value]
})

Vue.filter('personType', value => {
  const status = {
    natural: 'Persona física',
    fiscal: 'Persona moral',
  }
  return status[value]
})

Vue.filter('promoType', value => {
  const status = {
    free_product: 'Producto gratis',
    price_discount: 'Descuento',
    reward_points: 'Puntos recompensa',
    promo_code: 'Código de descuento',
  }
  return status[value]
})

Vue.filter('paymentStatus', value => {
  const status = {
    payed: 'Pagado',
    pending: 'Pendiente',
    cancelled: 'Cancelado',
  }
  return status[value]
})

Vue.filter('establishmentRoles', value => {
  const status = {
    establishment_admin: 'Administrador general',
    store_clerk: 'Cajero',
    dispatcher: 'Despachador',
    establishment_presale_clerk: 'Vendedor',
    establishment_supplier: 'Proveedor',
  }
  return status[value]
})

Vue.filter('automaticRestockingTypeFilter', value => {
  const status = {
    by_date: 'Por fecha',
    by_stock: 'Por nivel de inventario',
  }
  return status[value]
})

Vue.filter('restockScheduleFilter', value => {
  const status = {
    weekly: 'Semanal',
    monthly: 'Mensual',
  }
  return status[value]
})

Vue.filter('deliveryStatus', value => {
  const status = {
    assigning: 'Asignar',
    assigned: 'Despachador asignado',
    on_the_way: 'En camino',
    rejected_by_dispatcher: 'Rechazado por el despachador',
    delivered: 'Entregado',
    cancelled_by_establishment: 'Cancelado por tienda',
    cancelled_by_customer: 'Cancelado por cliente',
  }
  return status[value]
})

Vue.filter('invoicedStatus', value => {
  const status = {
    not_invoiced: 'No facturado',
    partially_invoiced: 'Parcialmente',
    fully_invoiced: 'Facturado',
  }
  return status[value]
})

Vue.filter('deliveryStatusIcon', value => {
  const status = {
    assigning: 'UsersIcon',
    assigned: 'UserCheckIcon',
    on_the_way: 'TruckIcon',
    rejected_by_dispatcher: 'XIcon',
    delivered: 'CheckSquareIcon',
    cancelled_by_establishment: 'XCircleIcon',
    cancelled_by_customer: 'XCircleIcon',
  }
  return status[value]
})

Vue.filter('deliveryStatusClass', value => {
  const status = {
    assigning: 'text-warning',
    assigned: 'text-primary',
    on_the_way: 'text-info',
    rejected_by_dispatcher: 'text-danger',
    delivered: 'text-success',
    cancelled_by_establishment: 'text-danger',
    cancelled_by_customer: 'text-danger',
  }
  return status[value]
})

Vue.filter('deliveryStatusForDispatcher', value => {
  const status = {
    assigning: 'Tienes una nueva entrega',
    assigned: 'Tienes una nueva entrega',
    on_the_way: 'Estás en camino',
    rejected_by_dispatcher: 'Has rechazado la entrega',
    delivered: 'Lo lograste, pedido entregado',
    cancelled_by_establishment: 'Cancelado por negocio',
    cancelled_by_customer: 'Cancelado por cliente',
  }
  return status[value]
})

Vue.filter('deliveryStatusForDispatcherClass', value => {
  const status = {
    assigning: 'text-warning',
    assigned: 'text-warning',
    on_the_way: 'text-info',
    rejected_by_dispatcher: 'text-danger',
    delivered: 'text-success',
    cancelled_by_establishment: 'text-danger',
    cancelled_by_customer: 'text-danger',
  }
  return status[value]
})

Vue.filter('orderMode', value => {
  const status = {
    online: 'En linea',
    onsite: 'En tienda',
  }
  return status[value]
})

Vue.filter('orderModeIcon', value => {
  const status = {
    online: 'GlobeIcon',
    onsite: 'UsersIcon',
  }
  return status[value]
})

Vue.filter('orderModeClass', value => {
  const status = {
    online: 'text-warning',
    onsite: 'text-success',
  }
  return status[value]
})

Vue.filter('orderTypeIcon', value => {
  const status = {
    inventory_loss: 'XSquareIcon',
    sell: 'TrendingUpIcon',
    buy: 'TrendingDownIcon',
    emida: 'TrendingUpIcon',
    loan_payment: 'TrendingUpIcon',
    inventory_correction: 'AlertCircleIcon',
  }
  return status[value]
})

Vue.filter('orderTypeClass', value => {
  const status = {
    inventory_loss: 'text-danger',
    sell: 'text-success',
    buy: 'text-danger',
    emida: 'text-success',
    loan_payment: 'text-success',
    inventory_correction: 'text-warning',
  }
  return status[value]
})

Vue.filter('paymentType', value => {
  const status = {
    cash: 'Efectivo',
    walleat: 'WallEat',
    bankcard: 'Tarjeta bancaria',
    reward_points: 'Puntos Walleat',
    bank_deposit: 'Deposito o transferencia bancaria',
    codi: 'CoDi',
  }
  return status[value]
})

Vue.filter('accountType', value => {
  const status = {
    walleat: 'WallEat',
    reward_points: 'Puntos Walleat',
    emida_a: 'Recargas',
    emida_b: 'Pago servicios',
    invoice: 'Facturas',
    stripe: 'Tarjeta bancaria',
  }
  return status[value]
})

Vue.filter('promotionType', value => {
  const status = {
    price_discount: 'Descuento',
    reward_points: 'Puntos recompensa',
    free_product: 'Producto gratis',
    promo_code: 'Código de descuento',
  }
  return status[value]
})

Vue.filter('paymentTypeNum', value => {
  const status = {
    0: 'Efectivo',
    1: 'WallEat',
    2: 'Tarjeta bancaria',
    3: 'Puntos de recompensa',
    4: 'Transferencia bancaria',
    5: 'CoDi',
  }
  return status[value]
})

Vue.filter('comissionStatus', value => {
  const status = {
    false: 'Pendiente de cobro',
    true: 'Cobrado',
  }
  return status[value]
})

Vue.filter('emidaTypeAccount', value => {
  const status = {
    emida_a: 'Recargas de saldo',
    emida_b: 'Pago de servicios',
  }
  return status[value]
})

/* eslint-disable-next-line */
Vue.filter('hideMail', value => value.replace(/(.{3})(.*)(?=@)/, (gp1, gp2, gp3) => { for (let i = 0; i < gp3.length; i += 1) { gp2 += '*' } return gp2 }))

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')
  return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')
